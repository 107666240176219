import React from 'react'
import Fade from 'react-reveal/Fade'
const Qualities = () => {
  return (
    <div className='sm:p-28 px-5 py-16 bg-yellow-200 text-black'>
        <div className='sm:w-3/4  mx-auto pb-16 space-y-4 text-center'>
            <Fade bottom>
            <p  style={{ fontFamily : "Croissant One"}} className='sm:text-5xl text-3xl text-center font-bold'>What Sets Us Apart</p>
            <p className='text-sm sm:text-md'>Our legacy is built upon a strong belief in optimizing resources to deliver the finest quality rice to our customers.Join us on this journey toward quality, sustainability, and prosperity in the world of rice production.</p>
            </Fade>
        </div>

         <div className='grid sm:grid-cols-4 gap-8'>
            <Fade bottom>
            <Card
            title={"Premium Parboiled Rice"}
            text={"Our primary goal is to produce the finest parboiled rice for our customers. We achieve this through a blend of cutting-edge technology and unwavering dedication to quality."}
            img={"rice.jpg"}/>
            </Fade>
            <Fade bottom>
            <Card
            title={"Scale and Capability"}
            text={"With the capacity to produce over 1,000 bags of rice daily, we stand as a significant player in the rice production industry. We cater to the needs of diverse rice business vendors and industries, not only within Osun State but also beyond its borders."}
            img={"beulah7.png"}/>                
            </Fade>
            <Fade bottom>
            <Card
            title={"Certifications and Trademark"}
            text={"We are proud bearers of certifications from the National Agency for Food and Drug Administration and Control (NAFDAC) and the Standard Organization of Nigeria (SON). Our business is backed by a trusted trademark, reinforcing our commitment to authenticity and quality."}
            img={"beulah4.png"}/>
            </Fade>
            <Fade bottom>
            <Card
            title={"Community Growth"}
            text={"Beulah Rice is more than a business; we are a force for positive change in Osun State. Our operations have created employment opportunities for more than 50 staff, both directly and indirectly. We are equally proud to have fostered growth among small and medium-sized enterprises (SMEs) in the state.            "}
            img={"comm.jpg"}/>
            </Fade>
         </div>
    </div>
  )
}


const Card = ({title, text, img}) => (
    <div className='bg-white flex flex-col justify-between rounded shadow'>
        <div className='p-10 space-y-5'>
            <p className='text-2xl text-bg'  style={{ fontFamily : "Croissant One"}}>{title}</p>
            <p className='text-sm'>
                {text}
            </p>
        </div>
        <img src={img} className='w-full h-48 object-cover' alt="" />
    </div>
)
export default Qualities