import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'

const AboutUs = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"About Beulah Rice"}/>

        <div className='bg-white px-5 py-16 sm:p-24'>
        <p>We are a world class rice production company with state of the art factory in Osogbo, Osun State. We 
produce rice in mass production for different rice business vendors and industries both within and 
outside Osun State. Our strong believe is to produce premium parboiled rice to our customers through 
various production technology and strategy. We are aware of the limited resources available to our 
people and we take conscious steps to assist our customers in ensuring maximum usage of resources to 
get the best quality rice.</p>
<br />
<p>At <b>Beulah Rice</b>, we have the total capacity to assist our customers in carefully producing quality rice at 
the best price. We have various forms of tailor-made world class rice production equipment with the 
capacity to produce over 1,000 bags of rice daily.</p>
<br />
<p>We are certified by National Agency for Food and Drug Administration and Control (NAFDAC), 
Standard Organization of Nigeria (SON) and we also have trade mark for our business.
We have contributed positively to the economic growth of Osun state by employing more than 50 staff
both direct & indirect and also create more business opportunity for SMEs within the state.</p>
<br />
<p><b>Beulah Rice</b> is committed to meeting and surpassing the customers’ need.We shall welcome opportunity to
discuss with you on howourproductcanassist in the achievement of your company’sgoals.</p>
<br />
<p>We shall be available to give further information and explanation if required by the management.</p>
<br />
<p>We count on your usual understanding and cooperation.</p>
<br />
        </div>
        <Footer/>
    </div>
  )
}

export default AboutUs