import React from 'react'
import { Link } from 'react-router-dom/dist'
import Fade from 'react-reveal/Fade'
const About = () => {
  return (
    <div className='sm:p-20 px-5 py-16 gap-10 bg-white grid sm:grid-cols-2'>
        <div className='sm:p-10'>
            <Fade bottom>
                <img src="bags.jpg" className='w-full' alt="" />
            </Fade>
        </div>
        <div className='sm:p-10 space-y-5'>
            <div>
                <Fade bottom>
                <p className='text-green-500 font-semibold text-lg'>ABOUT BEULAH RICE</p>
                <p className='text-4xl sm:text-5xl' style={{ fontFamily : "Croissant One"}}>LEADERS IN RICE PRODUCTION</p>
                </Fade>
            </div>

            <div className='py-4 space-y-4'>
                <Fade bottom>
            <p> 
                We are a world class rice production company with state of the art factory in Osogbo, Osun State. We 
                produce rice in mass production for different rice business vendors and industries both within and 
                outside Osun State. Our strong believe is to produce premium parboiled rice to our customers through 
                various production technology and strategy. We are aware of the limited resources available to our 
                people and we take conscious steps to assist our customers in ensuring maximum usage of resources to 
                get the best quality rice.
            </p>
            <p>
                At Beulah Rice, we have the total capacity to assist our customers in carefully producing quality rice at 
                the best price. We have various forms of tailor-made world class rice production equipment with the 
                capacity to produce over 1,000 bags of rice daily.
                We are certied by National Agency for Food and Drug Administration and Control (NAFDAC), 
                Standard Organization of Nigeria (SON) and we also have trade mark for our business.
            </p>
                <p className='text-bg'><b>Experience Quality. Choose Beulah Rice.</b></p>

                </Fade>

            </div>

            <Link to={"/about"}>
                <button className='bg-yellow-400 text-bg px-8 py-3 text-lg shadow rounded'>Learn More</button>
            </Link>
        </div>
    </div>
  )
}

export default About