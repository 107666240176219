import React from 'react'

const Header = ({title}) => {
  return (
    <div className='relative'>
        <img src="farm.jpg" className='h-full w-full absolute object-cover -z-10' alt="img" />
    
        <div className='p-16 sm:p-24 flex bg-gray-800 bg-opacity-50 justify-center items-center text-white'>
            <p style={{ fontFamily : "Croissant One"}}  className='text-4xl sm:text-6xl py-10'>{title}</p>
        </div>
    </div>
  )
}

export default Header