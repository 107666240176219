import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AboutUs from './pages/about';
import ScrollToTop from './components/ScrollToTop';

const router = createBrowserRouter([
  {
    path : "/",
    element : <ScrollToTop/>,
    children : [
      {
        index : true,
        element: <App/>,
      },
      {
        path: "/about",
        element: <AboutUs/>,
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

