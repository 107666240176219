import React from 'react'
import { Fade } from 'react-reveal'

const Mission = () => {
  return (
    <div className='bg-bg py-16 grid sm:grid-cols-2 gap-8' id='mission'>
        <Fade bottom>
        <div className='sm:p-20 p-5 space-y-8 flex flex-col justify-center'>
            <h4 className='text-yellow-500 font-bold text-5xl'>Our Mission</h4>
            <p className='text-white'>
                At BEULAH RICE LIMITED we are totally committed to being a
                reputable Agro Processing Company rendering quality driven and
                customer focused services based on a culture of excellence by
                making use of our dedicated and highly skilled and dedicated
                workforce and modern technology.
            </p>
        </div>
        <div className='p-10'>
            <img src="./bags.png" className='h-80' alt="bags" />
        </div>
        </Fade>
    </div>
  )
}

export default Mission