import React from 'react'

const Section = () => {
  return (
    <div className='w-full relative'>
        <img src="https://img.freepik.com/free-photo/green-field_1417-1576.jpg?w=740&t=st=1695244186~exp=1695244786~hmac=f179ba5fc6c2b818f9283ebd116beef20142d6c26a9464f71c2bfb1b201421ff" className='absolute -z-10 h-full w-full object-cover' alt="" />

        <div className='w-full h-full text-white py-40 flex text-center gap-8 flex-col justify-center items-center z-20 bg-gray-800 bg-opacity-70'>
            <p className='capitalize text-3xl sm:text-5xl w-3/4' style={{ fontFamily : "Croissant One"}}>
                Send in your order let's deliver you the best rice
            </p>
            <button className='bg-yellow-400 px-8 py-3 text-lg shadow rounded'>Order Now</button>
        </div>
    </div>
  )
}

export default Section