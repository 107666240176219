import { EnvelopeIcon, MapIcon, PhoneIcon } from '@heroicons/react/24/solid'
import React from 'react'

const Footer = () => {
  return (
    <>
    <div className='sm:p-24 px-5 py-16 bg-bg grid sm:grid-cols-3 font-light text-sm text-gray-200 gap-20'>

        <div className='space-y-5'>
            <div className='flex gap-4 items-center'>
                <img src="logo2.png" className='h-28' alt="" />
                {/* <p className='text-3xl font-bold text-yellow-400'>Beulah Rice</p> */}
            </div>
            <p>Beulah Rice is a world-class rice production company based in Osogbo, Osun State. Our commitment lies in producing premium parboiled rice through cutting-edge technology and sustainable practices. We proudly serve a diverse range of rice vendors and industries, both locally and internationally.</p>            
        </div>

        <div className='space-y-10' id='contact'>
            <p className='font-bold text-2xl inline border-b border-yellow-400 text-white'>Contact Us</p>

            <div className='space-y-5'>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <PhoneIcon className='h-4'/>
                    <span><a className='underline' href="tel:08051666323">08051666323</a>, <a className='underline' href="tel:+447543742458">+447543742458</a>, <a className='underline' href="tel:09078391839">09078391839</a></span>
                </div>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <EnvelopeIcon className='h-4'/>
                    <a className='underline' href='mailto:beulahriceltd@gmail.com'>beulahriceltd@gmail.com</a>
                </div>
                <div className='flex items-start gap-4'>
                    <MapIcon className='h-4 flex-shrink-0'/>
                    <span>KM 10 Osogbo Ikirun Road
                        Opposite Fortunate Bakery 
                        Olohunda Local Government Area 
                        Osogbo.
                        Osun State.
                    </span>
                </div>
                <div className='flex items-start gap-4'>
                    <MapIcon className='h-4 flex-shrink-0'/>
                    <span>7, Olu Akerele Street,
                        Off Obafemi Awolowo way,
                        Ikeja, Lagos
                    </span>
                </div>
            </div>
        </div>

        <div className='space-y-10'>
            <p className='font-bold text-2xl inline border-b border-yellow-400 text-white'>Socials</p>

            <div className='space-y-5'>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <div className='h-10 w-10 flex items-center justify-center flex-shrink-0 rounded-full border-2'><i className="fa fa-facebook-f text-xl"></i></div>
                    <span>@beulahriceltd</span>
                </div>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <div className='h-10 w-10 flex items-center justify-center flex-shrink-0 rounded-full border-2'><i className="fa fa-linkedin text-xl"></i></div>
                    <span>@beulahriceltd</span>
                </div>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <div className='h-10 w-10 flex items-center justify-center flex-shrink-0 rounded-full border-2'><i className="fa fa-instagram text-xl"></i></div>
                    <span>@beulahriceltd</span>
                </div>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <div className='h-10 w-10 flex items-center justify-center flex-shrink-0 rounded-full border-2'><i className="fa fa-twitter text-xl"></i></div>
                    <span>@beulahriceltd</span>
                </div>
            </div>
        </div>
    </div>
    <div className='bg-gray-800 py-4 text-center'>
        <p className='text-sm text-gray-500'>&copy; 2022. Designed by Peachy Inc.</p>
    </div>
    </>
  )
}

export default Footer